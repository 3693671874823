import React, { useState } from "react";
import FestivalCard from "../components/Card/FestivalCard";
import { festivals } from "../components/Data/festivalsData";
import "./styles/FestivalList.css";

const FestivalList = () => {
  const [expandedFestival, setExpandedFestival] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleFestivalClick = (festivalName) => {
    setExpandedFestival((prevExpandedFestival) =>
      prevExpandedFestival === festivalName ? null : festivalName
    );
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFestivals = festivals.filter((festival) =>
    festival.festival.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groupedFestivals = filteredFestivals.reduce((acc, festival) => {
    const key = festival.festival;
    if (!acc[key]) {
      acc[key] = [festival];
    } else {
      acc[key].push(festival);
    }
    return acc;
  }, {});

  return (
    <div className="festivalcard-container">
      <div className="search-container">
        <input
          type="text"
          placeholder="Zoeken.."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      {Object.entries(groupedFestivals).map(([festivalName, festivals]) => (
        <div key={festivalName}>
          <FestivalCard
            imageUrl={festivals[0].imageLink}
            description={festivals[0].festival}
            date={festivals[0].datum}
            buttonText="Expand"
            onClick={() => handleFestivalClick(festivalName)}
            expanded={expandedFestival === festivalName}
            festivalBuses={festivals}
          />
        </div>
      ))}
    </div>
  );
};

export default FestivalList;
