export const festivals = [
  {
    festival: "Dominator",
    link: "1",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/dominator?clean_iframe=on",
    imageLink: "https://i.ytimg.com/vi/cWJndtjFSd4/maxresdefault.jpg",
  },
  {
    festival: "Ground Zero",
    link: "2",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://cdn-az.allevents.in/events7/banners/8d970dab1d3cab1bf16857604bbcf91980f79e76e8ed4ff2b6d40fa67613d4d2-rimg-w960-h540-gmir.jpg?v=1686197663",
  },
  {
    festival: "Decibel",
    link: "3",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://d2oiyt60aney7e.cloudfront.net/2ck28wkq03nm/7m9kJb9gflkm9xAjQZqlv3/a3cbcde83c7fa491f0cc69a5bf7ceca1/Moneyshot.jpeg",
  },
  {
    festival: "Supremacy",
    link: "4",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://www.supremacy.nl/wp-content/uploads/2022/06/210925-231448-SUPREMACY-107387-Nils_HR-scaled.jpg",
  },
  {
    festival: "We Are Hardstyle",
    link: "4",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://static.wixstatic.com/media/a6034c_587cebcdf3564d9ab96b0a90794f66ed~mv2.png/v1/fill/w_980,h_650,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/a6034c_587cebcdf3564d9ab96b0a90794f66ed~mv2.png",
  },
  {
    festival: "Karnaval Festival",
    link: "5",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://images0.persgroep.net/rcs/5bKSkLENNDoYps1J0CC8irZnDlI/diocontent/167069544/_fitwidth/694/?appId=21791a8992982cd8da851550a453bd7f&quality=0.8",
  },
  {
    festival: "Masters of Hardcore",
    link: "6",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://www.mastersofhardcore.com/wp-content/uploads/2019/07/Schermafbeelding-2019-05-09-om-10.25.31-1280x719.jpg",
  },
  {
    festival: "Masters of Hardcore",
    link: "7",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://www.mastersofhardcore.com/wp-content/uploads/2019/07/Schermafbeelding-2019-05-09-om-10.25.31-1280x719.jpg",
  },
  {
    festival: "Supersized Kingsday",
    link: "8",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://d2oiyt60aney7e.cloudfront.net/2ck28wkq03nm/5wrj7pDnB6D6Bdg0dIeLWK/8f86cc05ed1bcec1c16c697492b96af5/MicrosoftTeams-image__7_.png?w=1024",
  },
  {
    festival: "Rebirth",
    link: "9",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://www.rebirth-festival.nl/app/uploads/2019/06/20190414_Rebirth__MNO-Photo_0056_.jpg",
  },
  {
    festival: "Harmony of Hardcore",
    link: "10",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://images0.persgroep.net/rcs/9J75ZDoV-JQj86muFZo57xTi_Tw/diocontent/150189736/_fill/1600/899/?appId=21791a8992982cd8da851550a453bd7f&quality=0.9",
  },
  {
    festival: "Intents",
    link: "11",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://www.intentsfestival.nl/wp-content/uploads/2022/05/home2022-768x512.jpg",
  },
  {
    festival: "Shockerz",
    link: "12",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://images0.persgroep.net/rcs/9J75ZDoV-JQj86muFZo57xTi_Tw/diocontent/150189736/_fill/1600/899/?appId=21791a8992982cd8da851550a453bd7f&quality=0.9",
  },
  {
    festival: "Thunderdome",
    link: "13",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://partyflock.nl/images/party/445685_495x330_642107/Shockerz.jpg",
  },
  {
    festival: "Rebellion",
    link: "14",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://cdn.rebirth-festival.nl/2021/03/20191123-Rebellion-Rossumedia-0165-2162-1920x1080.jpg",
  },
  {
    festival: "Gearbox",
    link: "15",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://partyflock.nl/images/party/414235_1920x1000_591245/Gearbox.jpg",
  },
  {
    festival: "Uptempo and you know it",
    link: "16",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/uptempo-and-you-know-it?clean_iframe=on",
    imageLink:
      "https://partyflock.nl/images/party/452181_1920x711_662714/Uptempo-And-You-Know-It.jpg",
  },
  {
    festival: "Wish Outdoor",
    link: "17",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://www.wishoutdoor.com/cache/2587.2587/2587-s1920x1080-q80.jpg",
  },
  {
    festival: "Rocketfest",
    link: "18",
    paymentLink:
      "https://shop.ikbenaanwezig.nl/tickets/event/intents?clean_iframe=on",
    imageLink:
      "https://partyflock.nl/images/party/451987_1920x1080_657030/Rocketfest.jpg",
  },
];
